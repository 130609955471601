<template>
  <v-app class="bg">
    <v-app-bar density="compact" flat app>
      <v-btn plain disabled>
        <v-icon class="c-purple" icon="mdi-instagram"></v-icon>
        <span>nimabakes</span>
      </v-btn>
      <v-btn plain disabled>
        <v-icon class="c-purple" icon="mdi-facebook"></v-icon>
        <span>nimabakes</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-app-bar-title class="c-purple"
        ><strong> NIMA</strong>BAKES</v-app-bar-title
      >

      <v-spacer></v-spacer>

      <v-btn disabled plain>
        <v-icon class="c-purple" icon="mdi-email"></v-icon>
        <span>info@nimabakes.com</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <v-rsponsiv>
              <v-img :src="logo" :aspect-ratio="16 / 7" contains></v-img>
            </v-rsponsiv>
          </v-col>
          <v-col cols="12" class="text-center">
            <h1 class="text-h1 font-weight-bold nima">COMING SOON</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed bottom app>
      {{ new Date().getFullYear() }} — <strong>Nima Bakes</strong>
    </v-footer>
  </v-app>
</template>

<script>
import logo from "./assets/logo-pink.png";
export default {
  name: "App",

  components: {},

  data: () => ({
    logo
  }),
  methods: {
    emailUs() {}
  }
};
</script>
<style  >
body,
html {
  height: 100%;
  margin: 0;
}

.bg {
  /* The image used */
  background-image: url("./assets/background.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h1 {
  color: #f3f6d2; /*   #393267; */
  text-shadow: 1px 1px 2px #393267, 0 0 25px #ff0561, 0 0 5px #393267;
  /* text-shadow: -1px 5px #ff0561, 1px 5px #ff0561, 1px 5px #ff0561; */
  /* text-shadow: -1px 0 #ff0561, 0 1px #393267, 1px 0 #ff0561, 0 -1px #393267; */
}
.c-pink {
  color: #ff0561 !important;
}

.c-purple {
  color: #393267 !important;
}
.c-cream {
  color: #f3f6d2 !important;
}
</style>
