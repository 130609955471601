import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.sass'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'


export default createVuetify({
  // theme: {
  //   defaultTheme: 'nima',
  //   themes: {
  //     nima: {
  //       dark: false,
  //       colors: {
  //         background: '#FFFFFF',
  //         surface: '#FFFFFF',
  //         primary: '#6200EE',
  //         'primary-darken-1': '#3700B3',
  //         secondary: '#03DAC6',
  //         'secondary-darken-1': '#018786',
  //         error: '#B00020',
  //         info: '#2196F3',
  //         success: '#4CAF50',
  //         warning: '#FB8C00',
  //         main: "#FF0561",
  //       }
  //     }
  //   }

  // },
  components,
  directives,
})
